import React from "react";
import { colors } from "../style/theme";
import Navigation from "../components/navigation";
import useTranslation from "../hooks/useTranslations";
import SEO from "../components/seo";

export default function Index() {
  const tr = useTranslation();
  return (
    <div>
      <SEO metadata={{ title: "Targeting Health" }} />
      <div
        css={{
          position: "relative",
        }}
      >
        <video
          playsInline
          autoPlay
          muted
          loop
          poster="poster.jpg"
          css={{
            objectFit: "cover",
            height: "100vh",
            width: "100%",
            position: "sticky",
            top: 0,
            left: 0,
          }}
        >
          <source src="/assets/videos/homepage2.webm" type="video/webm" />
          Sorry, your browser doesnt support embedded videos.
        </video>

        <div
          css={{
            position: "absolute",
            zIndex: 2,
            top: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <h2
            css={{
              color: colors.light,
              fontSize: "48px",
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: "1.86px",
              lineHeight: "21px",
            }}
          >
            {tr("Targeting Health")}
          </h2>
          <h1
            css={{
              marginTop: "2rem",
              marginBottom: "2rem",
              color: colors.light,
              fontSize: "45px",
              fontWeight: "bold",
              letterSpacing: "2.88px",
              lineHeight: "41px",
              textAlign: "center",
            }}
          >
            {tr("Attacks Against Medical Facilities in Syria")}
          </h1>
          <div
            css={{
              boxSizing: "border-box",
              height: "1px",
              width: "745px",
              border: "1.8px solid #FF5400",
            }}
          />

          <p
            css={{
              marginTop: "2rem",
              marginBottom: "2rem",
              color: colors.light,
              width: "744px",
              fontSize: "25px",
              letterSpacing: "0.29px",
              lineHeight: "34px",
              textAlign: "center",
            }}
          >
            {tr(
              "Syrian Archive has preserved video documenting 410 separate, verified attacks between 2011 and 2020 against a total of 270 medical facilities in Syria."
            )}
          </p>

          <div
            css={{
              boxSizing: "border-box",
              height: "1px",
              width: "745px",
              border: "1px solid #FF5400",
            }}
          />

          <Navigation place="homepage" />
        </div>

        <div
          css={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            transition: "1s",
            background:
              "linear-gradient(180deg, rgba(0,0,0,0.50) 0%, rgba(175,58,0,0.22) 100%)",
          }}
        />
      </div>
    </div>
  );
}
